import React from 'react'
import { enquireScreen } from 'enquire-js'
import { graphql } from 'gatsby'
// import Markdown from 'react-markdown'

import Layout from '../components/layout'

import '../components/antd/less/antMotionStyle.less'
import SEO from '../components/seo'

let isMobile
enquireScreen(b => {
  isMobile = b
})

export default class Product extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile,
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
  }

  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { /* product, */ products, productGroups } = strapi
    return (
      <Layout
        products={products}
        productGroups={productGroups}
        curIndex={2}
        hasBg={true}
      >
        <SEO title='产品' />
        <div className='product-detail main-con'>
          <h1 style={{ textAlign: 'center' }}>产品页面</h1>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: ID!) {
    strapi {
      product(id: $id) {
        id
        title
      }
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
